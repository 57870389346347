import { Modal, Toast, ToastContainer } from 'react-bootstrap';
import './DaoDelegationModal.scss';
import { waitForTransaction } from '@wagmi/core';
import useAccount from '../../hooks/useAccountOverride';
import { useNetwork, useWalletClient } from 'wagmi';
import {  useState } from 'react';
import { getScanAddressByChainId, getZunStakingAddress } from '../../utils/zunami';
import stakingABI from '../../actions/abi/zun/ZUNStakingRewardDistributor.json';
import { log } from '../../utils/logger';
import { Preloader } from '../Preloader/Preloader';
import { teamMultiSig } from '../../sushi/lib/constants';
import useVotingPower from '../../hooks/useVotingPower';
import useBalanceOf from '../../hooks/useBalanceOf';

interface DaoDelegationModalProps {
    show: boolean;
    onHide?: Function;
}

export const DaoDelegationModal = (props: DaoDelegationModalProps): JSX.Element => {
    const { address } = useAccount();
    const wallet = useWalletClient();
    const { chain } = useNetwork();
    const [transactionError, setTransactionError] = useState(false);
    const [transactionId, setTransactionId] = useState<string | undefined>(undefined);
    const [pendingTx, setPendingTx] = useState(false);
    const chainId = chain ? chain.id : undefined;

    const delegate = async (toTeam: boolean = true) => {
        if (!wallet || !wallet.data) {
            return;
        }

        setPendingTx(true);

        try {
            const txHash = await wallet.data.writeContract({
                address: getZunStakingAddress(chainId),
                chain: chain,
                abi: stakingABI,
                functionName: 'delegate',
                args: [toTeam ? teamMultiSig : address],
                account: address,
            });

            setTransactionId(txHash);

            waitForTransaction({ hash: txHash }).then(() => {
                setPendingTx(false);
                alert('Voting power successfully delegated');
                window.location.reload();
            });
        } catch (error: any) {
            setTransactionError(error.message);
            log(`❗️ Error while delegating tokens: ${error.message}`);
            setPendingTx(false);
        }
    };

    return (
        <Modal
            show={props.show}
            backdrop="static"
            animation={false}
            keyboard={false}
            centered
            className="DaoDelegationModal"
            onHide={() => {
                if (props.onHide) {
                    props.onHide();
                }
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex gap-3 flex-column justify-content-center align-items-center">
                <ToastContainer position={'top-end'} className={'toasts mt-3 me-3'}>
                    {transactionError && (
                        <Toast onClose={() => setTransactionError(false)} delay={10000} autohide>
                            <Toast.Body>Sorry, we couldn't complete the transaction</Toast.Body>
                        </Toast>
                    )}
                    {transactionId && (
                        <Toast onClose={() => setTransactionId(undefined)} delay={15000} autohide>
                            <Toast.Body>
                                Success! Check out the{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://${getScanAddressByChainId(chainId)}/tx/${transactionId}`}
                                >
                                    transaction
                                </a>
                            </Toast.Body>
                        </Toast>
                    )}
                </ToastContainer>
                <div className="chameleon-text text-center">
                    <h5 className="mb-3">Delegate Vote Weight</h5>
                    Delegate your voting power to the team or participate in weekly ZUN distribution vote yourself (requires gas costs)
                </div>
                <div className="d-flex gap-2 mt-2">
                    <button
                        className={`zun-button ${pendingTx ? 'disabled' : ''}`}
                        onClick={async () => await delegate(true)}
                    >
                        Delegate to the team
                    </button>
                    <button
                        className={`btn btn-secondary ${pendingTx ? 'disabled' : ''}`}
                        onClick={() => {
                            if (props.onHide) {
                                props.onHide();
                            }
                        }}
                    >
                        Vote yourself
                    </button>
                </div>
                {pendingTx && <Preloader className="mt-2" />}
            </Modal.Body>
        </Modal>
    );
};
