import { format } from 'date-fns';
// import { useWallet } from 'use-wallet';
import './RebaseHistory.scss';
import { getScanAddressByChainId } from '../../utils/zunami';
import { useMemo } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import moment from 'moment';

interface TransactionHistoryProps {
    items: Array<any>;
    onPageEnd?: Function;
    emptyText: string;
    columns: Array<string>;
}

interface TransactionItem {
    dai: Number;
    usdc: Number;
    usdt: Number;
    dateTime: String;
    transactionHash: String;
    status: String;
    type: string;
}

export const RebaseHistory: React.FC<TransactionHistoryProps & React.HTMLProps<HTMLDivElement>> = ({
    className,
    items,
    onPageEnd,
    emptyText,
    columns,
}) => {
    const onScroll = (e: any) => {
        const areaHeight = e.target.offsetHeight;
        const totalScroll = e.target.scrollTop + areaHeight;
        const fullHeight = e.target.children[0].offsetHeight;

        if (totalScroll >= fullHeight) {
            if (onPageEnd) {
                onPageEnd();
            }
        }
    };

    return (
        <div className={`RebaseHistory ${className}`}>
            <div className="d-flex header">
                {columns.map((column: string, key: number) => (
                    <div key={key}>{column}</div>
                ))}
            </div>
            <div className="RebaseHistory__List" onScroll={onScroll}>
                {items &&
                    items.map((item: any, index) => (
                        <div className="RebaseHistory__List-Item d-flex" key={index}>
                            <div className="date text-center">
                                <div>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://etherscan.io/tx/${item.hash}`}
                                        className="tx-link d-flex gap-2 align-items-center justify-content-center"
                                    >
                                        {moment.utc(item.ts * 1000).format("DD MMM YYYY HH:mm")}
                                    </a>
                                </div>
                            </div>
                            <div className="details text-center d-flex align-items-center justify-content-center">
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="top"
                                    overlay={
                                        <Popover>
                                            <Popover.Body>
                                                {
                                                    item.tokens.map((token: any) => (
                                                        <div key={token.title} className="d-flex gap-2">
                                                            <div style={{ width: '60px' }}>{token.title}</div>
                                                            <div>
                                                                {Number(token.value).toLocaleString('en', { maximumFractionDigits: 3 })}
                                                                <span>&nbsp;</span>
                                                                <span>(${Number(token.inUsd).toLocaleString('en', { maximumFractionDigits: 0 })})</span>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </Popover.Body>
                                        </Popover>
                                    }
                                    // show={unclaimedPopoverShow}
                                >
                                    <div
                                        className="dropdown-btn d-flex gap-2 align-items-center"
                                        // onMouseEnter={() => setUnclaimedPopoverShow(true)}
                                        // onMouseLeave={() => setUnclaimedPopoverShow(false)}
                                    >
                                        Details
                                        <svg width="9" height="4" viewBox="0 0 9 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L4.65217 3L8 1" stroke="#909090" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </OverlayTrigger>

                            </div>
                            <div className="price text-center">
                                <span>${Number(item.totalInUsd).toLocaleString('en', { maximumFractionDigits: 0 })}</span>
                            </div>
                        </div>
                    ))}
                {!items.length && (
                    <div className="text-center empty">
                        {/* <svg
                            width="130"
                            height="24"
                            viewBox="0 0 130 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="130" height="24" rx="12" fill="#E4E4E4" />
                        </svg> */}
                        <div className="">{emptyText}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
