import Accordion from 'react-bootstrap/Accordion';
import { MicroCard } from '../../components/MicroCard/MicroCard';
import './VotingPool.scss';
import { Address, useNetwork } from 'wagmi';
import { useMemo, useState } from 'react';
import { bigNumberToNumber } from '../../utils/formatbalance';
import { Toast, ToastContainer } from 'react-bootstrap';
import { getScanAddressByChainId } from '../FastDepositForm/types';
import BigNumber from 'bignumber.js';
import useAccount from '../../hooks/useAccountOverride';

interface VotingPoolProps {
    index: string;
    pool: iVotingPool;
    onMax?: Function;
    onVoteChange?: Function;
    votes: Array<number>;
    allPoolVotes: BigNumber;
    allPoolPrevVotes: BigNumber;
}

export interface iVotingPool {
    address: Address;
    primaryIcon: string;
    secondaryIcons: Array<string>;
    title: string;
    description: string;
    currentVotes: BigNumber;
    apr: number;
    zunEmission: BigNumber;
    finalizedVotes: BigNumber;
}
// 0 -- 0x4A9077F2815fC5DB6AE00221A65360c5e6097122 - ZUN Staking (vlZUN)
// 1 -- 0x593e59A7D8ce65bFA64Ff5Ee3d8eB155180c31BF - zunUSD APS
// 2 -- 0xa5F3f35fcFC31742838Bad8e781F1cEc9fB9B821 - zunETH APS
// 3 -- 0xD7c689822cE9C99798C43426c9Fe53A2d8892fA3 - ZUN/WETH
// 4 -- 0x0Ce6df2119F649fF6f547bfaF8F4bB7C3B027be1 - zunETH/frxETH
// 5 -- 0x65c327bFb1405cD6E33143c6C16ED6AdE3268028 - zunUSD/fxUSD Votium
// 6 -- 0x1c572Bf5306c086b43f6F91F032296A25B49D920 - crvUSD+zunUSD Votemarket Bounty
// 7 -- 0xd3Ea7CF0a73D709029988Bf2cb65ACC568f79332 - crvUSD+zunUSD Arbitrum
// 8 -- 0xf8AfC5e9AEdA712A05E32F27513cDAa2F67f457B - zunETH/wETH Arbitrum Votemarket bounty
// 9 -- 0x4D9AbDEF5Da8F44d6Cd8f6654786276c092CBB1B - zunUSD/USDC Base Aerodrome
// 10 - 0x124Ab70402Eaf0bA6B18F0b304577c336dcF81df - ZUN/USDC Base Aerodrome
// 11 - 0xFff20BfA5aa8Bb208B8EeC461dDCA1e2034aECCd - ZUN/wstETH OP Velodrome
export function getPoolDescription(address: Address) {
    let title = '';
    let description = '';
    let primaryIcon = '';
    let secondaryIcons: Array<string> = [];

    switch (address) {
        case '0xD7c689822cE9C99798C43426c9Fe53A2d8892fA3':
            title = 'ZUN/WETH';
            description = 'Curve Finance';
            primaryIcon = 'curve-icon.svg';
            secondaryIcons = ['zunusd.svg', 'weth.svg'];
            break;
        case '0x593e59A7D8ce65bFA64Ff5Ee3d8eB155180c31BF':
            title = 'zunUSD APS';
            description = 'Zunami Protocol';
            primaryIcon = 'zun-solo.svg';
            secondaryIcons = ['zunusd.svg'];
            break;
        case '0xa5F3f35fcFC31742838Bad8e781F1cEc9fB9B821':
            title = 'zunETH APS';
            description = 'Zunami Protocol';
            primaryIcon = 'zun-solo.svg';
            secondaryIcons = ['zuneth.svg'];
            break;
        case '0x4A9077F2815fC5DB6AE00221A65360c5e6097122':
            title = 'ZUN Staking (vlZUN)';
            description = 'Zunami Protocol';
            primaryIcon = 'zun-solo.svg';
            secondaryIcons = ['zun.svg'];
            break;
        case '0x0Ce6df2119F649fF6f547bfaF8F4bB7C3B027be1':
            title = 'zunETH/frxETH';
            description = 'Votium Bounty';
            primaryIcon = 'votium.png';
            secondaryIcons = ['zuneth.svg', 'frxeth.svg'];
            break;
        case '0x65c327bFb1405cD6E33143c6C16ED6AdE3268028':
            title = 'zunUSD/fxUSD';
            description = 'Votium Bounty';
            primaryIcon = 'votium.png';
            secondaryIcons = ['zunusd.svg', 'fxusd.png'];
            break;
        case '0x1c572Bf5306c086b43f6F91F032296A25B49D920':
            title = 'crvUSD+zunUSD';
            description = 'Votemarket Bounty';
            primaryIcon = 'stake-dao.svg';
            secondaryIcons = ['crvusd.svg', 'zunusd.svg'];
            break;
        case '0xd3Ea7CF0a73D709029988Bf2cb65ACC568f79332':
            title = 'crvUSD+zunUSD Arbitrum';
            description = 'Votemarket Bounty';
            primaryIcon = 'stake-dao.svg';
            secondaryIcons = ['crvusd.svg', 'zunusd.svg'];
            break;
        case '0xf8AfC5e9AEdA712A05E32F27513cDAa2F67f457B':
            title = 'zunETH/wETH Arbitrum';
            description = 'Votemarket Bounty';
            primaryIcon = 'stake-dao.svg';
            secondaryIcons = ['zuneth.svg', 'weth.svg'];
            break;
        case '0x4D9AbDEF5Da8F44d6Cd8f6654786276c092CBB1B':
            title = 'zunUSD/USDC';
            description = 'Base Aerodrome';
            primaryIcon = 'aerodrome.svg';
            secondaryIcons = ['zunusd.svg', 'usdc.svg'];
            break;
        case '0x124Ab70402Eaf0bA6B18F0b304577c336dcF81df':
            title = 'ZUN/USDC';
            description = 'Base Aerodrome';
            primaryIcon = 'aerodrome.svg';
            secondaryIcons = ['zun.svg', 'usdc.svg'];
            break;
        case '0xFff20BfA5aa8Bb208B8EeC461dDCA1e2034aECCd':
            title = 'ZUN/wstETH';
            description = 'OP Velodrome';
            primaryIcon = 'velodrome.svg';
            secondaryIcons = ['zun.svg', 'wsteth.png'];
            break;
        case '0x9683e651b485A6640527f83264Ca57AaeBE59A68':
            title = 'zunETH/ZUN';
            description = 'Base Aerodrome';
            primaryIcon = 'aerodrome.svg';
            secondaryIcons = ['zun.svg', 'zuneth.svg'];
            break;
        case '0xAAba2Cfc0B7a486FF8309A2397DF7C8474fBf90F':
            title = 'zunUSD/DOLA';
            description = 'Base Aerodrome';
            primaryIcon = 'aerodrome.svg';
            secondaryIcons = ['zun.svg', 'dola.svg'];
            break;
        case '0x8C0E464112Ed1Ab159933cfbd20c790349c99E5e':
            title = 'ZUN/wETH';
            description = 'Votemarket Bounty';
            primaryIcon = 'stake-dao.svg';
            secondaryIcons = ['zun.svg', 'weth.svg'];
            break;
        case '0xACe78395dF6c7Fd269ee7c6a7fEFe8B212cf3fEc':
            title = 'zunETH/pxETH';
            description = 'Votemarket Bounty';
            primaryIcon = 'stake-dao.svg';
            secondaryIcons = ['zuneth.svg', 'pxeth.png'];
            break;
        case '0xd5Da6Ca144BcBFDDb3EC4A3236fB893Db9A5eC35':
            title = 'zunUSD APS on Base';
            description = 'Zunami Protocol';
            primaryIcon = 'zun-solo.svg';
            secondaryIcons = ['zunusd.svg'];
            break;
    }

    return {
        title,
        description,
        primaryIcon,
        secondaryIcons,
    };
}


export function renderToasts(
    transactionError: boolean,
    setTransactionError: Function,
    chainId: number,
    transactionId: string | undefined,
    setTransactionId: Function
) {
    return (
        <ToastContainer position={'top-end'} className={'toasts mt-3 me-3'}>
            {transactionError && (
                <Toast onClose={() => setTransactionError(false)} delay={10000} autohide>
                    <Toast.Body>Sorry, we couldn't complete the transaction</Toast.Body>
                </Toast>
            )}
            {transactionId && (
                <Toast onClose={() => setTransactionId(undefined)} delay={15000} autohide>
                    <Toast.Body>
                        Success! Check out the{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://${getScanAddressByChainId(chainId)}/tx/${transactionId}`}
                        >
                            transaction
                        </a>
                    </Toast.Body>
                </Toast>
            )}
        </ToastContainer>
    );
}

export const VotingPool: React.FC<VotingPoolProps & React.HTMLProps<HTMLDivElement>> = ({
    className,
    index,
    pool,
    onVoteChange,
    onMax,
    votes,
    allPoolVotes,
    allPoolPrevVotes
}) => {
    const { address: account } = useAccount();
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : undefined;
    const [depositSum, setDepositSum] = useState('0');

    const prevEpochVotes = useMemo(() => {
        let result: number = bigNumberToNumber(new BigNumber(pool.finalizedVotes.toString()));
        return result.toFixed(0);
    }, [pool.finalizedVotes]);

    const poolPercent = useMemo(() => {
        if (!pool.currentVotes.toNumber() || !allPoolVotes.toNumber()) {
            return 0;
        }

        return bigNumberToNumber(pool.currentVotes) / bigNumberToNumber(allPoolVotes) * 100;
    }, [pool.currentVotes, allPoolVotes]);

    const poolPrevPercent = useMemo(() => {
        if (!pool.finalizedVotes || !allPoolPrevVotes.toNumber()) {
            return 0;
        }

        return bigNumberToNumber(pool.finalizedVotes) / bigNumberToNumber(allPoolPrevVotes) * 100;
    }, [pool.finalizedVotes, allPoolPrevVotes]);

    return (
        <Accordion.Item eventKey={index} className="dao-pool-voting-row">
            <Accordion.Header>
                <div className="d-flex pool-row">
                    <div className="d-flex align-items-center pool">
                        <div className="header">Pool name</div>
                        <img src={getPoolDescription(pool.address).primaryIcon} alt="" className="primary-icon" />
                        <div className="divider ms-2 me-2"></div>
                        <div className="coins">
                            {
                                getPoolDescription(pool.address).secondaryIcons.map((icon: string) => <img key={icon} src={icon} alt="" />)
                            }
                        </div>
                        <div className="titles">
                            <div className="primary chameleon-text">{pool.title}</div>
                            <div className="secondary">{pool.description}</div>
                        </div>
                    </div>
                    <div className="tvl vela-sans d-flex flex-column align-items-start justify-content-center">
                        <div className="header">My votes</div>
                        <div className="chameleon-text">0</div>
                    </div>
                    <div className="apr vela-sans">
                        <div className="header">APR</div>
                        <div className="chameleon-text">{pool.apr !== -1 ? `${pool.apr}%` : 'soon'}</div>
                    </div>
                    <div className="deposit-val vela-sans">
                        <div className="header">Est. ZUN Emissions</div>
                        <div className="chameleon-text">0</div>
                    </div>
                    <div className="claimed vela-sans d-flex flex-column align-items-end">
                        <div className="header">Total votes</div>
                        <div className="d-flex align-items-center gap-2">
                            <div className="chameleon-text">
                                {
                                    Number(bigNumberToNumber(pool.currentVotes)).toLocaleString('en', { maximumFractionDigits: 0 })
                                } vlZUN ({
                                poolPercent.toFixed(2)
                            }%)</div>
                        </div>
                        <div className="prev-votes">
                            prev. {Number(prevEpochVotes).toLocaleString('en', { maximumFractionDigits: 0 })}
                            ({
                                poolPrevPercent.toFixed(2)
                            }%)
                        </div>
                    </div>
                </div>
                <div className="d-flex d-lg-none gap-2 w-100 first-row-counters mt-3">
                    <MicroCard title="TVL" value={0} />
                    <MicroCard title="APR" value={`${0}%`} hint="Some tooltip content" />
                    <MicroCard title="Deposit" value={'0'} />
                </div>
                <div className="d-flex d-lg-none gap-2 w-100 second-row-counters mt-3">
                    <MicroCard title="Already claimed" value={'0'} />
                    <MicroCard title="Unclaimed" value={'0'} />
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="row">
                            <div className="action-col">
                                <div className="d-flex gap-2 align-items-center">
                                    <div className="action-hint">Enter a percentage</div>
                                    <div className="input">
                                        <input
                                            type="number"
                                            value={votes[Number(index)]}
                                            inputMode={'decimal'}
                                            autoComplete={'off'}
                                            autoCorrect={'off'}
                                            pattern={'^[0-9]*[.,]?[0-9]*$'}
                                            placeholder={'10'}
                                            min={1}
                                            max={100}
                                            minLength={1}
                                            maxLength={3}
                                            onChange={(e) => {
                                                if (onVoteChange) {
                                                    let val = Number(e.target.value);

                                                    if (val > 100) {
                                                        val = 100;
                                                    }

                                                    onVoteChange(Number(index), val);
                                                }
                                            }}
                                            className="vote-percentage-input"
                                        />
                                        <button
                                            className="max"
                                            onClick={(e) => {
                                                if (onMax) {
                                                    onMax(index);
                                                }
                                            }}
                                        >
                                            MAX
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
};
